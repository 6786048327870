import React from 'react';

const ContentWebApps = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4633 43.6177C12.2767 43.6177 12.0978 43.5435 11.9659 43.4116C11.8339 43.2796 11.7598 43.1007 11.7598 42.9142V26.0295C11.7615 25.9384 11.7828 25.8484 11.8216 25.766C11.8604 25.6835 11.916 25.6103 11.9851 25.5509L18.4296 19.0783C18.4952 19.0102 18.5734 18.9563 18.6604 18.9193C18.7474 18.8823 18.8413 18.8633 18.9359 18.8633C19.0305 18.8633 19.1244 18.8823 19.2114 18.9193C19.2984 18.9563 19.3766 19.0102 19.4422 19.0783C19.5649 19.213 19.6332 19.3888 19.6332 19.5709C19.6332 19.7531 19.5649 19.9288 19.4422 20.0635L13.1668 26.339V42.8578C13.1746 42.9548 13.1623 43.0524 13.1304 43.1443C13.0986 43.2363 13.0481 43.3206 12.982 43.392C12.9159 43.4634 12.8356 43.5205 12.7464 43.5593C12.6571 43.5981 12.5606 43.618 12.4633 43.6177Z"
      fill="#475467"
    />
    <path
      d="M21.7222 38.4882C21.5861 38.4864 21.4533 38.4453 21.3402 38.3697C21.2271 38.294 21.1382 38.187 21.0846 38.0619C21.031 37.9368 21.0154 37.7989 21.0386 37.6648C21.0618 37.5306 21.1233 37.4062 21.2158 37.3065C21.5967 36.9756 21.9056 36.5701 22.1234 36.1151C22.3412 35.6601 22.4632 35.1651 22.4821 34.661C22.37 33.7871 21.9393 32.9852 21.2722 32.4096C21.1905 32.347 21.1245 32.2659 21.0805 32.1729C21.0364 32.0799 21.0153 31.9779 21.0187 31.8751C21.0085 31.7768 21.0209 31.6775 21.0551 31.5848C21.0892 31.4921 21.1444 31.4084 21.2158 31.3402L25.0427 27.513C25.736 26.8639 26.1635 25.9804 26.2415 25.0339C26.3196 24.0873 26.0429 23.1454 25.4652 22.3915L17.501 30.3553C17.3655 30.4569 17.1979 30.5065 17.029 30.4945C16.8601 30.4825 16.7015 30.4098 16.5818 30.2901C16.462 30.1703 16.389 30.0114 16.377 29.8425C16.365 29.6735 16.4142 29.5059 16.5158 29.3705L24.9582 20.9281C25.0296 20.8612 25.1143 20.8094 25.2062 20.7756C25.298 20.7418 25.3956 20.7267 25.4934 20.7313C25.5845 20.733 25.6741 20.754 25.7565 20.7928C25.8389 20.8316 25.9121 20.8872 25.9715 20.9563C26.4821 21.4544 26.8879 22.0496 27.1649 22.7069C27.4419 23.3641 27.5847 24.0702 27.5847 24.7834C27.5847 25.4967 27.4419 26.2027 27.1649 26.86C26.8879 27.5173 26.4821 28.1125 25.9715 28.6106L22.6229 31.9596C23.3101 32.7456 23.7244 33.7327 23.8046 34.7737C23.805 35.4776 23.6548 36.1731 23.3635 36.8139C23.0723 37.4546 22.6471 38.0256 22.1166 38.4882C21.9884 38.5291 21.8503 38.5291 21.7222 38.4882Z"
      fill="#475467"
    />
    <path
      d="M26.9845 43.6183C26.798 43.6183 26.6191 43.5441 26.4871 43.4122C26.3552 43.2803 26.281 43.1014 26.281 42.9148V39.8753C26.2804 39.6981 26.3465 39.5272 26.4665 39.3968C26.5865 39.2664 26.7515 39.186 26.9282 39.1718C27.6881 39.1727 28.4191 38.8807 28.9694 38.3566C29.5197 37.8326 29.8463 37.1167 29.8825 36.3577V32.9809C29.8825 32.7943 29.9566 32.6154 30.0886 32.4835C30.2205 32.3515 30.3994 32.2773 30.586 32.2773C30.7726 32.2773 30.9522 32.3515 31.0841 32.4835C31.216 32.6154 31.2895 32.7943 31.2895 32.9809V36.3577C31.3006 37.3778 30.9418 38.3673 30.2796 39.1433C29.6174 39.9193 28.6972 40.4293 27.6881 40.5788V42.9989C27.6673 43.1705 27.5835 43.3286 27.4538 43.4428C27.324 43.557 27.1574 43.6196 26.9845 43.6183Z"
      fill="#475467"
    />
    <path
      d="M28.8134 47.9554C28.6268 47.9554 28.4479 47.8812 28.316 47.7493C28.184 47.6174 28.1099 47.4385 28.1099 47.2519V43.6219H11.2252V47.2519C11.2252 47.4385 11.151 47.6174 11.0191 47.7493C10.8871 47.8812 10.7082 47.9554 10.5216 47.9554C10.3351 47.9554 10.1562 47.8812 10.0242 47.7493C9.89229 47.6174 9.81812 47.4385 9.81812 47.2519V42.9184C9.81812 42.7318 9.89229 42.5525 10.0242 42.4206C10.1562 42.2887 10.3351 42.2148 10.5216 42.2148H28.8134C29 42.2148 29.1789 42.2887 29.3108 42.4206C29.4428 42.5525 29.5169 42.7318 29.5169 42.9184V47.2519C29.5169 47.4385 29.4428 47.6174 29.3108 47.7493C29.1789 47.8812 29 47.9554 28.8134 47.9554Z"
      fill="#475467"
    />
    <path
      d="M35.0614 33.4595H25.0993C24.9128 33.4595 24.7339 33.3857 24.6019 33.2538C24.47 33.1218 24.3958 32.9426 24.3958 32.756C24.3958 32.5694 24.47 32.3905 24.6019 32.2586C24.7339 32.1267 24.9128 32.0525 25.0993 32.0525H35.0614C35.3476 32.0451 35.6195 31.9264 35.8192 31.7213C36.019 31.5163 36.1313 31.2415 36.1312 30.9553V2.50426C36.1349 2.36148 36.1097 2.21955 36.0576 2.08654C36.0056 1.95353 35.9272 1.83227 35.8275 1.72997C35.7278 1.62767 35.6087 1.54637 35.4771 1.49088C35.3455 1.43539 35.2043 1.40701 35.0614 1.40706H20.7098C20.567 1.40701 20.4251 1.43539 20.2935 1.49088C20.1619 1.54637 20.0428 1.62767 19.9431 1.72997C19.8434 1.83227 19.7657 1.95353 19.7136 2.08654C19.6616 2.21955 19.6364 2.36148 19.6401 2.50426V25.0172C19.6401 25.2038 19.566 25.383 19.434 25.515C19.3021 25.6469 19.1232 25.7207 18.9366 25.7207C18.75 25.7207 18.5711 25.6469 18.4392 25.515C18.3072 25.383 18.2331 25.2038 18.2331 25.0172V2.50426C18.2293 2.17669 18.291 1.85195 18.4138 1.54824C18.5365 1.24454 18.7179 0.968079 18.9483 0.735133C19.1786 0.502187 19.4532 0.317205 19.7556 0.190997C20.0579 0.0647892 20.3823 -2.11467e-05 20.7098 5.1758e-09H35.0614C35.7208 0.00740904 36.3504 0.274481 36.8141 0.743377C37.2777 1.21227 37.5383 1.84486 37.5382 2.50426V30.9553C37.5383 31.6147 37.2777 32.2473 36.8141 32.7162C36.3504 33.1851 35.7208 33.4521 35.0614 33.4595Z"
      fill="#475467"
    />
    <path
      d="M36.8345 28.6755H29.3485C29.1619 28.6755 28.9831 28.6014 28.8511 28.4694C28.7192 28.3375 28.645 28.1586 28.645 27.972C28.645 27.7854 28.7192 27.6065 28.8511 27.4746C28.9831 27.3427 29.1619 27.2685 29.3485 27.2685H36.131V4.75554H19.64V25.1861C19.64 25.3727 19.5658 25.5515 19.4339 25.6835C19.3019 25.8154 19.123 25.8896 18.9364 25.8896C18.7499 25.8896 18.571 25.8154 18.439 25.6835C18.3071 25.5515 18.2329 25.3727 18.2329 25.1861V4.02384C18.2329 3.83725 18.3071 3.65836 18.439 3.52642C18.571 3.39449 18.7499 3.32031 18.9364 3.32031H36.8345C37.0211 3.32031 37.2 3.39449 37.3319 3.52642C37.4639 3.65836 37.5381 3.83725 37.5381 4.02384V28.0002C37.5308 28.1819 37.4535 28.3535 37.3223 28.4794C37.1912 28.6053 37.0163 28.6757 36.8345 28.6755Z"
      fill="#475467"
    />
    <path
      d="M28.3922 3.03596H27.3788C27.1922 3.03596 27.0133 2.96179 26.8814 2.82985C26.7495 2.69792 26.6753 2.51902 26.6753 2.33244C26.6753 2.14585 26.7495 1.96696 26.8814 1.83502C27.0133 1.70308 27.1922 1.62891 27.3788 1.62891H28.3922C28.5788 1.62891 28.7577 1.70308 28.8896 1.83502C29.0216 1.96696 29.0957 2.14585 29.0957 2.33244C29.0957 2.51902 29.0216 2.69792 28.8896 2.82985C28.7577 2.96179 28.5788 3.03596 28.3922 3.03596Z"
      fill="#475467"
    />
    <path
      d="M29.4895 31.0672H26.2536C26.067 31.0672 25.8881 30.993 25.7562 30.8611C25.6242 30.7292 25.55 30.5503 25.55 30.3637C25.55 30.1771 25.6242 29.9982 25.7562 29.8663C25.8881 29.7343 26.067 29.6602 26.2536 29.6602H29.4895C29.6761 29.6602 29.855 29.7343 29.987 29.8663C30.1189 29.9982 30.1931 30.1771 30.1931 30.3637C30.1931 30.5503 30.1189 30.7292 29.987 30.8611C29.855 30.993 29.6761 31.0672 29.4895 31.0672Z"
      fill="#475467"
    />
  </svg>
);

export default ContentWebApps;
